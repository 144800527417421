import React from "react";

const InvaildOrganization = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center "
      style={{ width: "100%", height: "100vh" }}
    >
      Please Use Valid Exam Url.
    </div>
  );
};

export default InvaildOrganization;

import axios from "axios";
import serverPath from "../serverPath";

export const authAxios = axios.create({
    baseURL: serverPath.CANDIDATE_OTP,
    headers: {
        "Content-Type": "application/json"
    }
})

export const Question = axios.create({
    baseURL: serverPath.CANDIDATE_OTP,
    headers: {
        "Content-Type": "application/json"
    }
})

export const Reducer=(state={selectedOption:[]}, action)=>{
    switch(action.type){
        case 'selected-answer':
            const{questionIndex, selectedAnswer} = action.payload;
            const newSelectedOptions= [...state.selectedOption];
            newSelectedOptions[questionIndex] = selectedAnswer;
            return{
                ...state,
                selectedOption:newSelectedOptions,
            }
    }
}
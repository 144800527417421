import "./login.css";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelopeOpen,
  faIdBadge,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { authAxios } from "../helper/axios-instance/candidateOtp";
import { toast } from "react-toastify";
import { organisation } from "../helper/axios-instance/CanddiateQuestion";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
toast.configure({
  autoClose: 2000,
  pauseOnFocusLoss: false,
});
const Login = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [timer, setTimer] = useState();
  const [minute, setMinute] = useState(0);
  const [submit, setSubmit] = useState(false);
  const [isResendOTP, setIsResendOTP] = useState(false);
  const [sentStatus, setSentStatus] = useState("");
  const [verificationStatus, setVerificationStatus] = useState("");
  const [getOrganization, setGetOrganization] = useState();
  const [logoLoading, setLogoLoading] = useState(true);
  const [validCaptcha, setValidCaptcha] = useState(false);
  const REACT_APP_RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  const onSubmit = async (data) => {
    try {
      const requestBody = {
        email: data.email,
        candidate_id: data.candidate_id,
        otp: data.otp,
      };
      const response = await authAxios.post(`/otp/verify`, requestBody);
      const token = response.data.data.token;
      if (response.status === 200) {
        const timeDuration = response.data.data.test_duariton;
        const localtime = localStorage.getItem("duration");

        if (localtime == null) {
          localStorage.setItem("duration", timeDuration);
        }
        // setVerificationStatus(<p className='successText'>{response.data.message}</p>)
        toast.success(response.data.message);
        setSentStatus("");

        Cookies.set("candidate_token", token);
        Cookies.set("candidate_uuid", response.data.data.candidate_uuid);
        Cookies.set("app_id", data.candidate_id);

        navigate(`/test`, { state: timeDuration });
      }
    } catch (error) {
      if (error.response.status === 400 || error.response.status === 500) {
        toast.error(error.response.data.message);

        // setVerificationStatus(<p className='validText'>{error.response.data.message}</p>)
        setSentStatus("");
      }
    }
  };
  const sendOtp = async (data) => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(async () => {
        try {
          const requestBody = {
            email: data.email,
            candidate_id: data.candidate_id,
          };
          const response = await authAxios.post("/otp", requestBody);
          if (response.status === 200) {
            toast.success(response.data.message);
            // setSentStatus(<p className='successText'>{response.data.message}</p>)
            setVerificationStatus("");
            setSubmit(true);
          } else {
            // setSuccessMessage({ error: response?.data?.message, success: null });
          }
        } catch (error) {
          console.log(error);
          if (error.response.status === 400 || error.response.status === 500) {
            toast.error(error.response.data.message);
          }
          setVerificationStatus("");
        }
      })
      .catch(() => {
        toast.error("Turn on Camera and Microphone, and try again");
      });
  };
  useEffect(() => {
    let time = 60;
    setIsResendOTP(false);
    const timerInst = setInterval(() => {
      if (time > 0) {
        time -= 1;
        setTimer(time);
        setMinute(0);
      } else {
        clearInterval(timerInst);
      }
    }, 1000);
    return () => {
      clearInterval(timerInst);
    };
  }, [submit, isResendOTP]);
  const [searchParams] = useSearchParams();
  const OrganizationId = searchParams.get("key");
  Cookies.set("OrganizationId", OrganizationId);
  useEffect(() => {
    const getOrganization = async () => {
      setLogoLoading(true);
      organisation
        .get("/check?key=" + OrganizationId)
        .then((res) => {
          setLogoLoading(false);
          setGetOrganization(res.data.data.organisation_image);
        })
        .catch((error) => {
          setLogoLoading(false);
        });
    };

    getOrganization();
  }, []);
  const verifyCaptcha = async (token) => {
    if (token === null) {
      setValidCaptcha(false);
    }
    await axios
      .post(process.env.REACT_APP_QUESTION_API + "auth/verify/recaptcha", {
        token: token,
      })
      .then((response) => {
        setValidCaptcha(true);
      })
      .catch((error) => {
        navigate("0");
      });
  };
  return (
    <div>
      <div className="login-info">
        <div className="card-main">
          <div className="row align-items-center ">
            <div className="col-md-6 ">
              <div className="description-div">
                <h4 className="mb-3">Some Key points to remember</h4>
                <ul>
                  <li>
                    <>Please ensure Your Camera and Audio Access Required</>
                  </li>
                  <li>
                    <>
                      Before logging in, kindly verify that your device has a
                      stable internet connection.
                    </>
                  </li>
                  <li>
                    <>During Test Please Dont open other tab or switch tab</>
                  </li>
                  <li>
                    <>
                      During Test Dont try to close your browser as it will
                      consider as you have submitted test{" "}
                    </>{" "}
                  </li>
                  <li>
                    <>
                      Dont panic, If you By mistakely internet get disconnect or
                      connection problem dont worry try to login again with your
                      crendential
                    </>{" "}
                  </li>
                  <li>
                    <>
                      Login again if accidently you logout ,the test will resume
                      from where you end and data wont be lost
                    </>{" "}
                  </li>
                  <li>
                    <>Randomly It will Capture your Image</>{" "}
                  </li>
                  <li>
                    <>During test be visible to the camera</>{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 logincontainer">
              <div className="hrmlogo">
                {logoLoading ? (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    size={"2x"}
                    className="fa-spin"
                  />
                ) : (
                  <img
                    src={
                      getOrganization
                        ? "data:image/jpeg;charset=utf-8;base64," +
                          getOrganization
                        : "https://www.hrmware.com/images/logo-header.png"
                    }
                    alt="hrmwarelogo"
                  />
                )}
              </div>

              <div className="logincard">
                <form className="loginitems">
                  <div className="logingroup mb-2 mt-3">
                    <span className="input-group-text login__fieldicon bg-transparent">
                      <FontAwesomeIcon icon={faIdBadge} className="badgeIcon" />
                    </span>
                    <input
                      type="text"
                      {...register("candidate_id", {
                        required: "*Id is mandatory",
                      })}
                      placeholder="Enter your application id"
                      className="logininput"
                      disabled={submit}
                    />
                  </div>
                  {errors.candidate_id?.type === "required" && (
                    <p className="validText">{errors.candidate_id.message}</p>
                  )}
                  <div className="logingroup mb-2 mt-3">
                    <span className="input-group-text login__fieldicon bg-transparent">
                      <FontAwesomeIcon
                        icon={faEnvelopeOpen}
                        className="badgeIcon"
                      />
                    </span>
                    <input
                      type="email"
                      {...register("email", {
                        required: "*Email is mandatory",
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                          message: "Invalid Email Address",
                        },
                      })}
                      placeholder="Enter your email"
                      className="logininput"
                      disabled={submit}
                    />
                  </div>
                  {errors.email && (
                    <p className="validText">{errors.email.message}</p>
                  )}
                  {sentStatus && !submit && <p>{sentStatus}</p>}
                  {submit && (
                    <>
                      <div className="logingroup mb-2 mt-3">
                        <input
                          type="text"
                          {...register("otp")}
                          placeholder="Enter your OTP"
                          className="logininputOtp"
                        />
                      </div>
                      {sentStatus && <p>{sentStatus}</p>}
                      {verificationStatus && <p>{verificationStatus}</p>}
                    </>
                  )}
                  {!submit ? (
                    <>
                      <button
                        type="submit"
                        className="btn btn-primary custom-btn mb-2 mt-2"
                        onClick={handleSubmit(sendOtp)}
                      >
                        Send OTP
                      </button>
                    </>
                  ) : (
                    <>
                      {/* <ReCAPTCHA
                        sitekey={REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={(val) => {
                          verifyCaptcha(val);
                        }}
                      /> */}
                      <button
                        type="button"
                        // disabled={!validCaptcha}
                        className="btn btn-primary custom-btn mb-3 mt-3"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Login
                      </button>

                      <button
                        type="button"
                        className="btn btn-primary custom-btn mb-2 "
                        disabled={(timer || minute) === 0 ? false : true}
                        onClick={() => {
                          handleSubmit(sendOtp)();
                          setIsResendOTP(true);
                        }}
                      >
                        Resend OTP
                        <span
                          className={`${timer === 0 ? "d-none" : "d-block "} `}
                        >
                          {minute < 10 ? `0${minute}` : minute}:
                          {timer < 10 ? `0${timer}` : timer}
                        </span>
                      </button>
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

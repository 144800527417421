import { Outlet, useSearchParams } from "react-router-dom";
import InvaildOrganization from "../pages/InvaildOrganization";
import { organisation } from "../helper/axios-instance/CanddiateQuestion";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const OrganizationOnly = () => {
  const [searchParams] = useSearchParams();
  const OrganizationId = searchParams.get("key");
  const [validOrganization, setValidOrganization] = useState(false);
  const [loading, setLoading] = useState(true);

  organisation
    .get("/check?key=" + OrganizationId)
    .then((response) => {
      setValidOrganization(true);
      setLoading(false);
    })
    .catch((error) => {
      setLoading(false);
      setValidOrganization(false);
    });
  if (loading)
    return (
      <div className="min-vw-100 min-vh-100 d-flex justify-content-center align-items-center">
        <FontAwesomeIcon icon={faSpinner} size={"4x"} className="fa-spin" />
      </div>
    );
  else {
    if (validOrganization) {
      return <Outlet />;
    } else return <InvaildOrganization />;
  }
};

export default OrganizationOnly;

import { useState, useEffect, useRef } from "react";

import "./questionsPaper.css";
import Cookies from "js-cookie";
import { Editor } from "@monaco-editor/react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { candidate_test } from "../helper/axios-instance/CanddiateQuestion";
import isTokenExpired from "../helper/axios-instance/CanddiateQuestion";
import Header from "../header/Header";

import Webcam from "react-webcam";

toast.configure({
  autoClose: 2000,
  pauseOnFocusLoss: false,
});

const mimeType = 'video/webm; codecs="opus,vp8"';

const QuestionsPaper = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("javascript");
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const mediaRecorder = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [stream, setStream] = useState(null);
  const [recordedVideo, setRecordedVideo] = useState(null);
  const [videoChunks, setVideoChunks] = useState([]);
  const [permission, setPermission] = useState(false);
  const liveVideoFeed = useRef(null);

  const [cameraPermission, setCameraPermission] = useState(true);
  const [capturePermission, setCapturePermission] = useState(false); // Track permission for capturing screens
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const [QuestionList, setQuestionList] = useState([]);
  const [timeIsZero, setTimeIsZero] = useState(false);
  const savedQuestionIndex = localStorage.getItem("currentQuestionIndex");

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(
    savedQuestionIndex ? parseInt(savedQuestionIndex, 10) : 0
  );

  const [focustimeIndex, setFocustimeIndex] = useState(0);
  const [testPermissions, setTestPermissions] = useState({
    edit_answer: false,
    continue_test: false,
  });
  // Load selected answers from local storage
  const [selectedAnswer, setSelectedAnswer] = useState(
    JSON.parse(localStorage.getItem("answers")) || []
  );
  const { state } = useLocation();
  const OrganizationId = Cookies.get("OrganizationId");

  //camera end
  const startRecording = async () => {
    setRecordedVideo(null);
    //get video and audio permissions and then stream the result media stream to the videoSrc variable
    if ("MediaRecorder" in window) {
      try {
        const videoConstraints = {
          audio: false,
          video: true,
        };
        const audioConstraints = { audio: true };
        // create audio and video streams separately
        const audioStream = await navigator.mediaDevices.getUserMedia(
          audioConstraints
        );
        const videoStream = await navigator.mediaDevices.getUserMedia(
          videoConstraints
        );
        //combine both audio and video streams
        const combinedStream = new MediaStream([
          ...videoStream.getVideoTracks(),
          ...audioStream.getAudioTracks(),
        ]);
        setStream(combinedStream);
        //set videostream to live feed player
        liveVideoFeed.current.srcObject = videoStream;
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        setPermission(true);

        if (stream) {
          const mediaRecorderOptions = { mimeType: "video/webm" }; // Adjust mimeType as needed
          const media = new MediaRecorder(stream, mediaRecorderOptions);
          mediaRecorder.current = media;
          mediaRecorder.current.start();

          let localVideoChunks = [];
          mediaRecorder.current.ondataavailable = (event) => {
            if (typeof event.data === "undefined") return;
            if (event.data.size === 0) return;
            localVideoChunks.push(event.data);
          };
          setVideoChunks(localVideoChunks);

          setRecordingStatus("recording");
        }
      } catch (err) {
        const confirm_msg = window.confirm(err.message);
        if (confirm_msg) {
          toast.error(
            "Please give access to camera and audio to start the test"
          );
        }
      }
    } else {
      alert("The MediaRecorder API is not supported in your browser.");
    }
  };

  const stopRecording = () => {
    setPermission(false);
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
      setRecordingStatus("inactive");
      mediaRecorder.current.onstop = () => {
        const videoBlob = new Blob(videoChunks, { type: mimeType });
        const videoUrl = URL.createObjectURL(videoBlob);
        setRecordedVideo(videoUrl);
        setVideoChunks([]);
      };
    } else {
      console.warn(
        "MediaRecorder is not initialized or has already been stopped."
      );
    }
  };

  const [codeOutput, setCodeOutput] = useState("");
  const executeCode = () => {
    try {
      // Capture the code's output and set it in the codeOutput state variable
      const captureConsoleLog = (log) => {
        setCodeOutput((prevOutput) => prevOutput + log + "\n");
      };

      // Redirect console.log to captureConsoleLog
      const originalConsoleLog = console.log;

      // Execute the code
      const result = eval(code); // Execute the code (This is simplified and not recommended for production)

      // Restore original console.log
    } catch (error) {}
  };

  const appId = Cookies.get("app_id");
  const candidate_uuid = Cookies.get("candidate_uuid");

  const getQuestionList = async () => {
    try {
      const resposne = await candidate_test.get(
        `/question?candidate_uuid=${candidate_uuid}`
      );
      if (resposne.status === 200) {
        setQuestionList(resposne.data.data.questions);

        setTestPermissions(resposne.data.data.permission[0]);
      }
    } catch (error) {
      if (error.response.status === 400 || error.response.status === 500) {
        toast.error(error.response.data.message);
        localStorage.clear();
        Cookies.remove("candidate_token");
        if (error.response.data.message == "No question are set yet") {
          navigate(`/?key=${OrganizationId}`);
        }
      } else if (error.response.status === 401) {
        Cookies.remove("candidate_token");
        localStorage.clear();
        navigate(`/?key=${OrganizationId}`);
      }
    }
  };

  useEffect(() => {
    const checkTokenAndGetData = async () => {
      const isExpired = await isTokenExpired();
      if (!isExpired) {
        // getQuestionList();
      }
    };
    getQuestionList();
    checkTokenAndGetData();
  }, []);
  // Get the current question based on the currentQuestionIndex
  const currentQuestion = QuestionList[currentQuestionIndex];
  const handleNext = async () => {
    // Increment the current question index to show the next question
    if (currentQuestionIndex < QuestionList.length - 1) {
      try {
        localStorage.setItem("currentQuestionIndex", currentQuestionIndex + 1);
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        capture();
        const answer =
          selectedAnswer[currentQuestionIndex] !== undefined
            ? selectedAnswer[currentQuestionIndex]
            : code[currentQuestionIndex] !== undefined
            ? code[currentQuestionIndex]
            : null;

        // Store the question data in localStorage
        setCurrentQuestionIndex(currentQuestionIndex + 1);

        setFocustimeIndex(currentQuestionIndex);

        const answersData = QuestionList?.map((question, index) => {
          const questionId = question.question_uuid;
          const answer =
            selectedAnswer[index] !== undefined
              ? selectedAnswer[index]
              : code[index] !== undefined
              ? code[index]
              : null;
          return {
            question_id: questionId,
            answer: answer,
          };
        });
        cameraPermission &&
          localStorage.setItem("answerValue", JSON.stringify(answersData));
      } catch (error) {}
    }
  };
  const handlePrevious = () => {
    // Decrement the current question index to show the previous question
    if (currentQuestionIndex > 0) {
      localStorage.setItem("currentQuestionIndex", currentQuestionIndex - 1);
      setCurrentQuestionIndex(currentQuestionIndex - 1);

      setFocustimeIndex(currentQuestionIndex);
    }
  };
  useEffect(() => {
    // Retrieve answers from local storage
    const answersFromLocalStorage = JSON.parse(
      localStorage.getItem("answerValue")
    );

    if (answersFromLocalStorage) {
      // Create a copy of the current selectedAnswer state
      const updatedSelectedAnswer = [...selectedAnswer];

      // Iterate over the answers retrieved from local storage and update the selectedAnswer state
      answersFromLocalStorage.forEach((answer) => {
        const { question_id, answer: storedAnswer } = answer;

        // Find the index of the question_id in QuestionList
        const questionIndex = QuestionList.findIndex(
          (question) => question.question_uuid === question_id
        );

        if (questionIndex !== -1) {
          updatedSelectedAnswer[questionIndex] = storedAnswer;
        }
      });

      // Update the selectedAnswer state
      setSelectedAnswer(updatedSelectedAnswer);
    }
  }, [QuestionList]);

  const handleAnswerSelect = (optionIndex) => {
    const newSelectedOptions = [...selectedAnswer];
    newSelectedOptions[currentQuestionIndex] = optionIndex;
    setSelectedAnswer(newSelectedOptions);
    // dispatch(setSelectedAnswer(currentQuestionIndex, optionIndex))
  };
  const handleTextAnswerChange = (selectedOption) => {
    const newSelectedOptions = [...selectedAnswer];
    newSelectedOptions[currentQuestionIndex] = selectedOption;
    setSelectedAnswer(newSelectedOptions);
    // dispatch(setSelectedAnswer(currentQuestionIndex, textAnswer))
  };
  const [submitted, setSubmitted] = useState(false);
  // const [timer, setTimer] = useState(0);
  const [isActive, setIsActive] = useState(false);
  // let minutes = Math.floor(timer / 60);
  // let seconds = timer % 60;
  // minutes = minutes < 10 ? "0" + minutes : minutes;
  // seconds = seconds < 10 ? "0" + seconds : seconds;

  const focusOutTime = {
    candidate_id: appId,
    question_id:
      QuestionList[focustimeIndex ? focustimeIndex : currentQuestionIndex]
        ?.question_uuid,
  };

  const [focusTime, setFocusTime] = useState();
  const sendFocusOutTimeData = async (focus_in_time, focus_out_time) => {
    try {
      const response = await candidate_test.post(`/tab/focus`, {
        ...focusOutTime,
        focus_out_time,
        focus_in_time,
      });
      setFocusTime();
    } catch (error) {}
  };

  useEffect(() => {
    const handleBlur = () => {
      setIsActive(true);

      setFocusTime(new Date());
      if (timeIsZero) {
        sendFocusOutTimeData(focusTime, new Date());
      }
    };

    const handleFocus = () => {
      setIsActive(false);
      if (true) {
        sendFocusOutTimeData(new Date(), focusTime);
      }
    };

    window.addEventListener("blur", handleBlur);
    window.addEventListener("focus", handleFocus);

    return () => {
      // clearInterval(intervalId);
      window.removeEventListener("blur", handleBlur);
      window.removeEventListener("focus", handleFocus);
    };
  }, [isActive, timeIsZero]);

  const webcamRef = useRef(null);
  const currentQuestionRef = useRef();
  useEffect(() => {
    currentQuestionRef.current = currentQuestion;
  }, [currentQuestion]);

  if (!cameraPermission) {
    toast.warn("Camera is not Working");
    setTimeout(() => {
      navigate(`/?key=${OrganizationId}`);
    }, 4000);
  }

  const capture = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(() => {
        setCameraPermission(true);
        const imageSrc = webcamRef.current?.getScreenshot();
        // console.log(imageSrc);

        const webcamImageObj = {
          candidate_id: appId,
          question_id: currentQuestionRef.current?.question_uuid,
          image: imageSrc,
        };

        const webcamImageData = async () => {
          await candidate_test.post(`/capture/image`, webcamImageObj);
          // toast.info("Image has been Captured Successfully");
        };
        webcamImageData();
      })
      .catch(() => {
        setCameraPermission(false);
      });
  };

  const minTime = 1 * 60000; /* in minutes */
  const maxTime = 2 * 60000; /* in minutes */

  useEffect(() => {
    let interval = setInterval(() => {
      capture();
    }, Math.random() * (maxTime - minTime) + minTime);
    return () => clearInterval(interval);
  }, []);

  const handleSubmitTest = async () => {
    try {
      if (timeIsZero === true) {
        var answersData = QuestionList?.map((question, index) => {
          const questionId = question.question_uuid;
          const testId = question.test_id;
          const answer =
            selectedAnswer[index] !== undefined
              ? selectedAnswer[index]
              : code[index] !== undefined
              ? code[index]
              : null;
          return {
            question_id: questionId,
            test_id: testId,
            answer: answer,
          };
        });

        const response = await candidate_test.post(
          `/add/all/answer?app_id=${appId}`,
          answersData
        );
        if (response.status === 200) {
          Cookies.remove("candidate_token");
          localStorage.clear();
          if (!Cookies.get("candidate_token")) {
            navigate("/thankyou");
          }
        }
      } else {
        const alertmsg = window.confirm(
          "once test is submitted you cannot start the test"
        );
        if (alertmsg) {
          setSubmitted(true);
          var answersData = QuestionList?.map((question, index) => {
            const questionId = question.question_uuid;
            const testId = question.test_id;
            const answer =
              selectedAnswer[index] !== undefined
                ? selectedAnswer[index]
                : code[index] !== undefined
                ? code[index]
                : null;
            return {
              question_id: questionId,
              test_id: testId,
              answer: answer,
            };
          });
          const response = await candidate_test.post(
            `/add/all/answer?app_id=${appId}`,
            answersData
          );
          if (response.status === 200) {
            Cookies.remove("candidate_token");
            localStorage.clear();
            if (!Cookies.get("candidate_token")) {
              navigate("/thankyou");
            }
          }
        }
      }
    } catch (error) {
      if (error.response.status === 400 || error.response.status === 500) {
        <p className="validText">{error.response.data.message}</p>;
      }
    }
  };
  if (timeIsZero === true) {
    !testPermissions.continue_test && handleSubmitTest();
  }
  return (
    <>
      <Header state={state} setTimeIsZero={setTimeIsZero} />

      <section className="question-main">
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          mirrored
          minScreenshotWidth={200}
          minScreenshotHeight={150}
          onUserMedia={() => setCameraPermission(true)}
          onUserMediaError={() => setCameraPermission(false)}
          className="webcam"
        />
        {cameraPermission && (
          <div className="question">
            <div className="container my-auto">
              <div className="row">
                <div className="col-12">
                  {currentQuestion && (
                    <form
                      name={`question_${currentQuestionIndex}`}
                      className="questionform"
                    >
                      <figure className="question-card shadow-sm">
                        <div className="question-card-body">
                          <div className="question-card-question border-bottom">
                            <div className="question-card-number mb-4">
                              <p className="questionInfo my-0">
                                <strong>
                                  QUESTION {currentQuestionIndex + 1}/
                                  {QuestionList.length}
                                </strong>
                              </p>
                            </div>
                            <h6 className="mt-0 mb-3 questions">
                              {currentQuestion.question}
                            </h6>
                            <div className="custom_paraarea">
                              {/* <div className="my-2">
                              <p style={{ padding: currentQuestion.refrence_answer ? 20 : 0 }}>{currentQuestion.refrence_answer}</p>
                            </div> */}
                            </div>
                          </div>
                          <div className="question-card-answers">
                            {/* <canvas ref={canvasRef} style={{ display: 'none' }} /> */}
                            {(currentQuestion.question_type === 2 ||
                              currentQuestion.question_type === 5) && (
                              <ul className="question-list list-unstyled my-0">
                                {/* Render multiple-choice options here */}
                                {currentQuestion.options?.map(
                                  (option, optionIndex) => (
                                    <li
                                      className="question-item"
                                      key={optionIndex}
                                    >
                                      <input
                                        type="radio"
                                        name={`question_${currentQuestionIndex}`}
                                        id={`question_${currentQuestionIndex}_${optionIndex}`}
                                        checked={
                                          selectedAnswer[
                                            currentQuestionIndex
                                          ] === option
                                        }
                                        onChange={() =>
                                          handleAnswerSelect(option)
                                        }
                                      />
                                      <label
                                        htmlFor={`question_${currentQuestionIndex}_${optionIndex}`}
                                        className="question-label ml-2"
                                      >
                                        <span>{option}</span>
                                      </label>
                                    </li>
                                  )
                                )}
                              </ul>
                            )}
                            {currentQuestion.question_type === 1 && (
                              <textarea
                                placeholder="Write your answer here..."
                                className="question-input"
                                value={
                                  selectedAnswer[currentQuestionIndex] || ""
                                }
                                onChange={(e) =>
                                  handleTextAnswerChange(e.target.value)
                                }
                              />
                            )}
                            {currentQuestion.question_type === 3 && (
                              <>
                                <div className="language-dropdown">
                                  <label htmlFor="languageSelect">
                                    Select a language:{" "}
                                  </label>
                                  <select
                                    id="languageSelect"
                                    onChange={(e) =>
                                      setSelectedLanguage(e.target.value)
                                    }
                                    value={selectedLanguage}
                                  >
                                    <option value="javascript">
                                      JavaScript
                                    </option>
                                    <option value="python">Python</option>
                                    <option value="java">Java</option>
                                    <option value="c++">C++</option>
                                    <option value="c">C</option>
                                  </select>
                                </div>
                                <Editor
                                  height="300px"
                                  language={selectedLanguage}
                                  value={code[currentQuestionIndex] || ""}
                                  theme="vs-dark"
                                  options={{
                                    fontSize: "16px",
                                  }}
                                  onChange={(newCode) => {
                                    const updatedCodeForQuestions = [...code];
                                    updatedCodeForQuestions[
                                      currentQuestionIndex
                                    ] = newCode;
                                    setCode(updatedCodeForQuestions);
                                  }}
                                />
                              </>
                            )}
                            {currentQuestion.question_type === 4 && (
                              <>
                                <video
                                  ref={videoRef}
                                  autoPlay={true}
                                  muted={true}
                                />
                                <canvas
                                  ref={canvasRef}
                                  style={{ display: "none" }}
                                />
                                <div className="d-flex" id="buttonId">
                                  {!permission ? (
                                    <button
                                      onClick={startRecording}
                                      type="button"
                                      className="btn btn-primary"
                                      id="customButton"
                                    >
                                      Start
                                    </button>
                                  ) : null}
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    id="customButton"
                                    onClick={stopRecording}
                                  >
                                    Stop
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <figcaption className="question-card-footer d-flex">
                          <div className="question-card-tools"></div>
                          <div className="question-card-navigation ml-auto">
                            {currentQuestionIndex > 0 &&
                              testPermissions.edit_answer && (
                                <button
                                  type="button"
                                  className="btn btn-primary m-2"
                                  id="customButton"
                                  onClick={handlePrevious}
                                >
                                  <FontAwesomeIcon
                                    icon={faArrowLeft}
                                    className="fontIcon "
                                  />{" "}
                                  Previous question
                                </button>
                              )}
                            {currentQuestionIndex ===
                            QuestionList.length - 1 ? (
                              <button
                                type="button"
                                className="btn btn-primary"
                                id="customButton"
                                onClick={handleSubmitTest}
                              >
                                Submit
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-primary"
                                id="customButton"
                                onClick={handleNext}
                              >
                                Next question{" "}
                                <FontAwesomeIcon
                                  icon={faArrowRight}
                                  className="fontIcon"
                                />
                              </button>
                            )}
                          </div>
                        </figcaption>
                      </figure>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default QuestionsPaper;

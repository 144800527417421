import React from "react";

const NotFound = () => {
  return (
    <div
      className="w-100  d-flex justify-content-center align-items-center"
      style={{ height: "100dvh" }}
    >
      <div className="d-flex flex-column align-items-center justify-content-center">
        <span className="display-1">404</span>
        <span className="display-6">Page not Found</span>
      </div>
    </div>
  );
};

export default NotFound;
